import React from "react";
import style from "./FingerTouchIcon.module.css";
import classnames from "classnames";

export interface Props {
	size: number;

	animated?: boolean;
	strong?: boolean;
}

export default function (props: Props) {
	const shakingClass = (props.animated) ? style.shaking : undefined;
	const strong = (props.strong) ? style.strong : undefined;

    return (
        <svg x="0px" y="0px" viewBox="0 0 500 500" width={props.size} height={props.size} className={classnames(style.hand, strong)}>
			<path className={shakingClass} d="M438.827,186.347l-80.213-88.149c-15.872-15.872-41.728-15.893-57.749,0.128c-5.077,5.077-8.533,11.157-10.325,17.643
				c-15.957-12.224-38.976-11.008-53.675,3.691c-5.056,5.077-8.512,11.157-10.347,17.621c-15.957-12.181-38.976-10.987-53.653,3.712
				c-4.971,4.971-8.384,10.901-10.24,17.216l-37.803-37.803c-15.872-15.872-41.728-15.893-57.749,0.128
				c-15.893,15.872-15.893,41.728,0,57.621l145.237,145.237l-86.144,13.525c-23.275,3.328-40.832,23.552-40.832,47.083
				c0,17.643,14.357,32,32,32h201.152c31.339,0,60.8-12.203,82.965-34.368l33.557-33.557c22.144-22.123,34.325-51.563,34.325-82.859
				C469.333,235.989,458.496,207.979,438.827,186.347z M419.925,332.992l-33.557,33.557c-18.133,18.133-42.24,28.117-67.883,28.117
				H117.333c-5.888,0-10.667-4.779-10.667-10.667c0-12.971,9.685-24.128,22.677-25.984l106.987-16.811
				c3.968-0.619,7.232-3.413,8.491-7.232c1.237-3.797,0.235-8-2.603-10.837L82.155,163.072c-7.573-7.573-7.573-19.904,0.107-27.605
				c3.797-3.776,8.768-5.675,13.739-5.675c4.971,0,9.941,1.899,13.739,5.696l106.731,106.731c4.16,4.16,10.923,4.16,15.083,0
				c2.069-2.091,3.115-4.821,3.115-7.552s-1.045-5.461-3.136-7.552l-43.584-43.584c-7.573-7.573-7.573-19.883,0.128-27.584
				c7.552-7.552,19.904-7.552,27.456,0l43.605,43.605c4.16,4.16,10.923,4.16,15.083,0c2.069-2.091,3.115-4.821,3.115-7.552
				c0-2.731-1.045-5.461-3.136-7.552l-22.251-22.251c-7.573-7.573-7.573-19.883,0.128-27.584c7.552-7.552,19.904-7.552,27.456,0
				l22.357,22.357c0.043,0.021,0.021,0.021,0.021,0.021l0.021,0.021c0.021,0.021,0.021,0.021,0.021,0.021
				c0.021,0.021,0.021,0.021,0.021,0.021h0.021c0.021,0,0.021,0.021,0.021,0.021c4.181,3.968,10.795,3.883,14.869-0.213
				c4.16-4.16,4.16-10.923,0-15.083l-0.917-0.917c-3.669-3.669-5.696-8.555-5.696-13.739s2.005-10.048,5.803-13.845
				c7.595-7.552,19.883-7.531,27.115-0.363l79.872,87.787C439.125,218.389,448,241.301,448,265.216
				C448,290.816,438.037,314.88,419.925,332.992z"/>
			<path className={style.wave} d="M183.381,109.931C167.851,75.563,133.547,53.333,96,53.333c-52.928,0-96,43.072-96,96
				c0,37.547,22.229,71.851,56.597,87.403c1.429,0.64,2.923,0.939,4.395,0.939c4.053,0,7.936-2.347,9.728-6.272
				c2.411-5.376,0.021-11.691-5.333-14.123c-26.752-12.096-44.053-38.763-44.053-67.947c0-41.173,33.493-74.667,74.667-74.667
				c29.184,0,55.851,17.301,67.947,44.053c2.411,5.376,8.747,7.787,14.101,5.333C183.424,121.621,185.813,115.307,183.381,109.931z"
				/>
        </svg>
    )
}